import React from "react";
import "./Home.css";
import Typewriter from "typewriter-effect";
import MyCv from "./Gian_Quila_CV.pdf";
import { RxMoon } from "react-icons/rx";
import { GiSun } from "react-icons/gi";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";

const Home = ({ theme, changeTheme }) => {
  return (
    <div className="container-fluid home" id="home">
      <div className="theme-change" onClick={changeTheme}>
        {theme === "light" ? (
          <p>
            <RxMoon size={40} />
          </p>
        ) : (
          <p className="sun-theme-icon">
            <GiSun size={40} />
          </p>
        )}
      </div>

      <div className="container home-content">
        <Fade right>
          <h1> Hi! I'm a</h1>
          <h2>
            <Typewriter
              options={{
                strings: [
                  "Full Stack Web Developer",
                  "AS400 Developer",
                  "IT Analyst, Support and Services",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h2>
        </Fade>
        <Fade bottom>
          <div className="button-for-action">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={100}
              offset={-100}
            >
              <div className="hire-me-button">Hire Me</div>
            </Link>
            <div className="get-resume-button">
              <a href={MyCv} download="Gian_cv">
                Get Resume
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Home;
