import React, { useState } from "react";
import "./TechStack.css";
import Zoom from "react-reveal/Zoom";

const TechStack = () => {
  const data = [
    {
      name: "Full Stack Web Developer",
    },
    {
      name: "Python",
    },
    {
      name: "JavaScript",
    },
    {
      name: "CSS",
    },
    {
      name: "HTML5",
    },
    {
      name: "Express Js",
    },
    {
      name: "Node Js",
    },
    {
      name: "JQuery",
    },
    {
      name: "React Js",
    },
    {
      name: "Bootstrap",
    },
    {
      name: "Angular",
    },
    {
      name: "MongoDB",
    },
    {
      name: "Mongoose",
    },
    {
      name: "UI",
    },
    {
      name: "UX",
    },
    {
      name: "AS400 Software Developer",
    },
    {
      name: "RPG",
    },
    {
      name: "RPGILE",
    },
    {
      name: "CL",
    },
    {
      name: "SQL",
    },
  ];

  const color = [
    "#0066ff",
    "#6699ff",
    "#87CEFA",
    "#66ccff",
    "#0072B5",
    "#1E90FF",
    "#6F9FD8",
    "#8c9eff",
    "#00539C",
    "DeepSkyBlue",
    "#ADD8E6",
    "#5266ad",
    "#E0FFFF",
    "#91B0E8",
    "#3A71D7",
    "#1D4690",
    "#54bae3",
    "#6384BF",
    "#2eb6ff",
    "#2e7eff",
  ];

  const [showMoreTechStach, setshowMoreTechStack] = useState(9);

  const loadMore = () => {
    setshowMoreTechStack((prev) => prev + 3);
  };

  return (
    <Zoom>
      <div className="container techstack-section" id="techstack">
        <div className="section-title">
          <h5>Tech Stack</h5>
          <span className="line"></span>
        </div>

        <div className="row">
          {data.slice(0, showMoreTechStach).map((item, index) => (
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={index}>
              <div
                className={
                  index === 0 || index === 15
                    ? "tech-content-marked tech-content"
                    : "tech-content"
                }
              >
                <span
                  className="tech-number"
                  style={{ backgroundColor: color[index] }}
                >
                  {index + 1}
                </span>
                <p>{item.name} </p>
              </div>
            </div>
          ))}
        </div>
        {showMoreTechStach >= data.length ? null : (
          <span className="load-more-teck-stack" onClick={loadMore}>
            Load more
          </span>
        )}
      </div>
    </Zoom>
  );
};

export default TechStack;
