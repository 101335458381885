import React from "react";
import "./About.css";
import ProfilePic from "../../image/gianlogo.png";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Zoom";

const About = () => {
  return (
    <div className="container about-section" id="about">
      <div className="row">
        <Flip>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="about-image">
              <img src={ProfilePic} alt="My Profile Pic" />
            </div>
          </div>
        </Flip>
        <Zoom>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="about-details">
              <div className="about-title">
                <h5>About Me</h5>
                <span className="line"></span>
              </div>
              <p>
                Able to provide efficient and timely resolution of IT-related
                issues and concerns, and able to communicate effectively and
                proactively to end-users and manage needs/queries. Strong
                capability in developing and maintaining software and database
                systems, including recording procedures, analytics, and
                flowcharts. Self-motivated and dynamic to explore value
                improvement ideas and progressive learnings to permit better
                work efficiency, performance, and quality. Capable of mentoring
                and training co-workers and end- users, as needed, as part of a
                holistic approach to overall performance enhancement and
                personnel development. Outstanding work ethic and can thrive in
                a busy work environment with multiple priorities. Team player,
                detail-oriented, reliable, strong analytical, troubleshooting,
                debugging, and problem-solving skills. Able to manage time to
                perform quality work and personal life balance. Thorough
                understanding of IT and software concepts, software design
                development, and modeling.
              </p>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default About;
