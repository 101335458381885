import React from "react";
import "./SidebarList.css";
import profilepic from "../../image/gianlogo.png";
import {
  FcHome,
  FcAbout,
  FcFactory,
  FcTodoList,
  FcContacts,
  FcSalesPerformance,
} from "react-icons/fc";

import { MdBiotech } from "react-icons/md";

import { MdCastForEducation } from "react-icons/md";
import { Link } from "react-scroll";
import Zoom from "react-reveal/Zoom";

const SidebarList = ({ expandSidebar }) => {
  return (
    <Zoom>
      <React.Fragment>
        {expandSidebar ? (
          <div className="navbar-items">
            <div className="sidebar-profile-pic">
              <img src={profilepic} alt="My Pic" />
            </div>

            <ul>
              <li className="nav-item">
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcHome size={25} />
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcAbout size={25} color="#00ffbf" />
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="workexperience"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcFactory size={25} />
                  Work Experience
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="techstack"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <MdBiotech size={25} color="#1E90FF" />
                  Tech Stack
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="education"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <MdCastForEducation size={25} color="#8c9eff" />
                  Education
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="projects"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcTodoList size={25} />
                  Projects
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="testimonial"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcSalesPerformance size={25} />
                  Testimonial
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcContacts size={25} />
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div className="navbar-items-only-icons">
            <ul>
              <li className="nav-item">
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcHome size={25} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcAbout size={25} color="#00ffbf" />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="workexperience"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcFactory size={25} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="techstack"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <MdBiotech size={25} color="#1E90FF" />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="education"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <MdCastForEducation size={25} color="#8c9eff" />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="projects"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcTodoList size={25} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="testimonial"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcSalesPerformance size={25} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <FcContacts size={25} />
                </Link>
              </li>
            </ul>
          </div>
        )}
      </React.Fragment>
    </Zoom>
  );
};

export default SidebarList;
