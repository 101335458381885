import React from "react";
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Zoom from "react-reveal/Zoom";

const Testimonial = () => {
  const data = [
    {
      name: " Vanessa Quinn",
      position: "CEO",
      des: "With so many eye-popping websites online right now, it is so hard to say which are the best. I think Gian's website developemnt is one of the best. I really like his work, the site is definitely one of the best website designs we have seen in a while.",
      img: "https://www.freelancinggig.com/blog/wp-content/uploads/2020/05/kelly-sikkema-YK0HPwWDJ1I-unsplash-1-1140x641.jpg",
    },

    {
      name: "Richard Beste",
      position: "Project Manager",
      des: "G-Cod3 websites are tend to be highly effective in engaging users.",
      img: "https://th.bing.com/th/id/R.4622099de06a2a792aee812bfcb9a856?rik=KA8eEqP5ovhRLw&pid=ImgRaw&r=0",
    },

    {
      name: "Scott Amber",
      position: "CTO",
      des: "The best website designer and very good with front-end and back-end development",
      img: "https://th.bing.com/th/id/OIP.X0aSOLfo4oIcwN2yGrSU6AHaE7?pid=ImgDet&rs=1",
    },

    {
      name: "Primo Beltran",
      position: "Electrical Engineer",
      des: "The site is truly beautiful, making it stand alone visually as it delivers information simply and effectively. The design also proves that the business has value to add, as a marketing firm, by using its information delivery system to maintain web traffic on the site and display things in an eye-catching manner.",
      img: "https://compliantpapers.com/wp-content/uploads/2020/02/banner-gd-IT.jpg",
    },

    {
      name: "Henry Padi",
      position: "Business Owner",
      des: "Great website! Sections of content all placed inside a block layout, resulting in a unique yet familiar look in the Metro style that is user friendly and eye-catching with easy to read elements. A constant header at the top of the screen makes it easy to explore and return to previous navigations.",
      img: "https://th.bing.com/th/id/R.64122113e70c09776da0452ff2a121cd?rik=OmvK0hQCmkzaug&riu=http%3a%2f%2fak7.picdn.net%2fshutterstock%2fvideos%2f14097617%2fthumb%2f1.jpg%3fi10c%3dimg.resize(height%3a160)&ehk=aSF09DUBSqGZbV4OV3LsudYS%2bRRAEjEvBcv1LObWqF4%3d&risl=&pid=ImgRaw&r=0",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    autoplay: true,
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Zoom>
      <div className="container testimonial-section" id="testimonial">
        <div className="section-title">
          <h5>Testimonial</h5>
          <span className="line"></span>
        </div>

        <div className="testimonial-slider">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div className="content-slider-main">
                <div className="content-slider" key={index}>
                  <img
                    src={item.img}
                    alt="testimonial image"
                    className="center-image"
                  ></img>
                  <p>{item.name}</p>
                  <p>{item.position}</p>
                  <p>{item.des}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Zoom>
  );
};

export default Testimonial;
