import React from "react";
import "./Education.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaGraduationCap } from "react-icons/fa";

const Education = () => {
  const data = [
    {
      name: "AMA Computer University",
      degree: "Bachelor of Science in Information Technology",
      year: "2006-2011",
      description:
        "Bachelor of Science in Information Technology or more commonly known as BSIT has been one of the most sought-after courses in recent years, and rightfully so. Tech and IT graduates possess the right set of skills that can help them keep up with today’s most in-demand careers.",
    },

    {
      name: "Scrum Master",
      degree: "Licensed Scrum Master",
      year: "2022-2023",
      description:
        "Scrum Master is accountable for establishing Scrum. They do this by helping everyone understand Scrum theory and practice, both within the Scrum Team and the organization while serving the Scrum Team as well as the larger organization. ",
    },

    {
      name: "Product Owner",
      degree: "Licensed Scrum Product Owner",
      year: "2022-2023",
      description:
        "Product Owner is accountable for maximizing the value of the product resulting from the work of the Scrum Team. How this is done may vary widely across organizations, Scrum Teams and individuals.",
    },

    {
      name: "Cleo HArmony EDI Developer",
      degree: "Licensed EDI Developer",
      year: "2017",
      description:
        "Cleo Clarify supports every phase of business integration (building, integrating, and maintaining) in one simplified, streamlined product. An individual may create, test, and deploy integration processes from beginning to end",
    },
  ];

  const colors = ["#0066ff", "#6699ff", "#6F9FD8", "#66ccff"];

  return (
    <div className="container education-section" id="education">
      <div className="section-title">
        <h5>Education</h5>
        <span className="line"></span>
      </div>

      <div className="education-title">
        {/* Start of vertical Time line Element (Copied from the React website) */}
        <VerticalTimeline lineColor="#000099">
          {data.map((item, index) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: colors[index], color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date={item.year}
              dateClassName="date-class"
              iconStyle={{ background: colors[index], color: "#fff" }}
              icon={<FaGraduationCap />}
            >
              <h3 className="vertical-timeline-element-title">{item.name}</h3>
              <h6
                className="vertical-timeline-element-subtitle"
                style={{ color: "darkblue" }}
              >
                {item.degree}
              </h6>

              <p>{item.description}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>

        {/* End of vertical Time line Element (Copied from the React website) */}
      </div>
    </div>
  );
};

export default Education;
