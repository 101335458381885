import React from "react";
import "./WorkExperience.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdGroupWork } from "react-icons/md";

const WorkExperience = () => {
  const data = [
    {
      companyname: "G-Cod3",
      position: "Full Stack Web Developer",
      des: "Build and maintain both the front-end and the back-end of a website.",
      year: "2023-present",
      techskills: [
        {
          techname: "Python",
        },
        {
          techname: "React Js",
        },
        {
          techname: "Node Js",
        },
        {
          techname: "HTML5",
        },
        {
          techname: "CSS",
        },
        {
          techname: "JavaScript",
        },
        {
          techname: "Bootstrap",
        },
        {
          techname: "Express Js",
        },
      ],
    },

    {
      companyname: "EECOL",
      position: "Software Developer",
      des: "Design, analyze, code, test programs, create documentation, and train users.",
      year: "2014-2023",
      techskills: [
        {
          techname: "RPGILE",
        },
        {
          techname: "CL",
        },
        {
          techname: "SQL",
        },
        {
          techname: "Python",
        },
        {
          techname: "RPG",
        },
        {
          techname: "API",
        },
      ],
    },

    {
      companyname: "Micronet",
      position: "Systems Analyst",
      des: "Reviewing current IT systems, such as network, software and hardware features for any errors or issues.",
      year: "2011-2014",
      techskills: [
        {
          techname: "Slack",
        },
        {
          techname: "ClearInsights",
        },
        {
          techname: "DebuggerPro",
        },
        {
          techname: "N-Able",
        },
      ],
    },

    {
      companyname: "SCM Corp.",
      position: "I.T. Support / Analyst",
      des: "Analyzes, develops, implements and maintains different business application software and corresponding system architecture (hardware components, accessories, network) for the smooth flow of information necessary for timely relevant and effective business decisions.",
      year: "2009-2011",
      techskills: [
        {
          techname: "Windows Server",
        },
        {
          techname: "PowerShell",
        },
        {
          techname: "ITAM",
        },
        {
          techname: "ManageEngine",
        },
      ],
    },
  ];

  const colors = [
    "#0066ff",
    "#6699ff",
    "#6F9FD8",
    "#66ccff",
    "#00539C",
    "#87CEFA",
  ];

  return (
    <div className="container workexperience-section" id="workexperience">
      <div className="section-title">
        <h5>Work Experience</h5>
        <span className="line"></span>
      </div>
      <div className="workexperience-title">
        {/* Start of vertical Time line Element (Copied from the React website) */}
        <VerticalTimeline lineColor="#000099">
          {data.map((item, index) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: colors[index], color: "#fff" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date={item.year}
              dateClassName="date-class"
              iconStyle={{ background: colors[index], color: "#fff" }}
              icon={<MdGroupWork />}
            >
              <h3 className="vertical-timeline-element-title">
                {item.companyname}
              </h3>
              <h6 className="vertical-timeline-element-subtitle">
                {item.position}
              </h6>

              <div className="row">
                {item.techskills.map((tec, index) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="tech-skills">
                      <p>{tec.techname}</p>
                    </div>
                  </div>
                ))}
              </div>

              <p>{item.des}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>

        {/* End of vertical Time line Element (Copied from the React website) */}
      </div>
    </div>
  );
};

export default WorkExperience;
