import React, { useState } from "react";
import "./Sidebar.css";
import Home from "../Home/Home";

import {
  TbArrowBigLeftLinesFilled,
  TbArrowBigRightLinesFilled,
} from "react-icons/tb";
import SidebarList from "./SidebarList";

const Sidebar = ({ theme, changeTheme }) => {
  const [expandSidebar, setExpandSidebar] = useState(true);

  const handleExpandClick = () => {
    setExpandSidebar(!expandSidebar);
  };

  return (
    <div className="container-fluid sidebar-section">
      <div className={expandSidebar ? "sidebar-expand sidebar" : "sidebar"}>
        <div className="icon-for-sidebar-expand-and-collapse">
          <p onClick={handleExpandClick}>
            {expandSidebar ? (
              <TbArrowBigLeftLinesFilled size={40} />
            ) : (
              <TbArrowBigRightLinesFilled size={40} />
            )}
          </p>
        </div>

        <SidebarList expandSidebar={expandSidebar} />
      </div>

      <div className="container">
        <Home changeTheme={changeTheme} theme={theme} />
      </div>
    </div>
  );
};

export default Sidebar;
