import React, { useState, createContext } from "react";
import Sidebar from "./Components/Sidebar/Sidebar";
import About from "./Components/About/About";
import TechStack from "./Components/TechStack/TeckStack";
import Project from "./Components/Projects/Project";
import WorkExperience from "./Components/WorkExperience/WorkExperience";
import Education from "./Components/Education/Education";
import Testimonial from "./Components/Testimonial/Testimonial";
import Contact from "./Components/Contact/Contact";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import NavBarMobileView from "./Components/Sidebar/NavBarMobileView";

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState("light");

  const changeTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      <div id={theme}>
        <NavBarMobileView changeTheme={changeTheme} theme={theme} />
        <Sidebar changeTheme={changeTheme} theme={theme} />

        <About />
        <WorkExperience />
        <TechStack />
        <Education />
        <Project />

        <Testimonial />
        <Contact />
      </div>

      <ScrollToTop
        smooth={true}
        top="20"
        color="white"
        height="20px"
        width="20px"
        style={{
          borderRadius: "90px",
          background: "linear-gradient(to bottom, #00ccff 0%, #000099 100%)",
        }}
      />
    </ThemeContext.Provider>
  );
};

export default App;
