import React from "react";
import ProjectList from "./ProjectList";
import "./Project.css";

const Project = () => {
  const data = [
    {
      name: "Rock Band Website",
      des: "Queen and Aces Band is the brand of rock music. It has become as diverse as the rock music genre itself, and bands adopting this style are now further classified under more specific genres or combinations of genres",
      projectlink: "https://www.queenandaces.com",
      techused: [],
    },

    {
      name: "GCOD3 Website",
      des: "A strong design with patriotic colors for that familiar Canadian feel, with phone numbers, email, testimonial, projects, photos and videos",
      projectlink: "https://www.gcod3.com",
      techused: [],
    },
  ];

  return (
    <div className="container" id="projects">
      <div className="section-title">
        <h5>Projects</h5>
        <span className="line"></span>
      </div>
      <div className="row">
        {data.map((item, index) => (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>
            <ProjectList {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Project;
